<template>
  <div class="row" v-if="canShowPanel">
    <div class="col-12">
      <Panel header="Dashboard" toggleable>
          <div v-for="(config, index) in [donorTableConfig, matchTableConfig, offerTableConfig]" :key="index">
            <DynamicTable
              :table-id="config.tableId"
              :table-data="config.data"
              :loading="config.loading"
              :empty-message="config.emptyMessage"
              :table-headers="config.tableHeaders"
              :columns="config.columns"
              :paginate="config.paginate"
            />
            <Divider type="dashed" />
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>

import Panel from 'primevue/panel';
import Divider from 'primevue/divider';
import DynamicTable from "@/src/components/shared/DynamicTable.vue";

export default {
  components: {
    Panel,
    Divider,
    DynamicTable
  },
  props: {
    data: { required: true, type: Array },
    loading: { required: true, type: Boolean },

  },
  computed: {
    canShowPanel() {
      return this.data !== null || this.donorTableConfig !== null || this.matchTableConfig !== null || this.offerTableConfig !== null ? true : false;
    },
  },
  data() {
    return {
      donorTableConfig: null,
      matchTableConfig: null,
      offerTableConfig: null,
    }
  },
  watch: {
    data() {
      this.populateData();
    }
  },
  methods: {
    populateData() {
      if (this.data == null) {
        this.donorTableConfig = null;
        this.matchTableConfig = null;
        this.offerTableConfig = null;
        return null;
      }

      this.donorTableConfig = {
        tableId: 'troubleshoot-donors-table',
        data: this.data.donor,
        loading: this.loading,
        emptyMessage: 'No donors found!',
        tableHeaders: 'bk_donors',
        columns: [
          { field: 'code', header: 'Code', style: 'min-width: 100px;' },
          { field: 'age', header: 'Age', style: 'min-width: 100px;' },
          { field: 'hospital', header: 'Hospital', style: 'min-width: 100px;' },
          { field: 'admit_date_time', header: 'Admit datetime', style: 'min-width: 100px;' },
          { field: 'processing_status', header: 'Processing status', style: 'min-width: 100px;' },
          { field: 'processing_error', header: 'Processing error', style: 'min-width: 100px;' },
          { field: 'processing_started_at', header: 'Processing started at', style: 'min-width: 100px;' },
          { field: 'processing_completed_at', header: 'Processing completed at', style: 'min-width: 100px;' },
          { field: 'created_at', header: 'Created at', style: 'min-width: 100px;' },
          { field: 'updated_at', header: 'Updated at', style: 'min-width: 100px;' },
        ],
      };
      this.matchTableConfig = {
        tableId: 'troubleshoot-matches-table',
        data: this.data.matches,
        loading: this.loading,
        emptyMessage: 'No matches found!',
        tableHeaders: 'bk_matches',
        columns: [
          { field: 'donor.code', header: 'Donor', style: 'min-width: 100px;' },
          { field: 'code', header: 'Match', style: 'min-width: 100px;' },
          { field: 'transplant_center.code', header: 'TxC', style: 'min-width: 100px;' },
          { field: 'organ.organ_group.name', header: 'Organ', style: 'min-width: 100px;' },
          { field: 'status.name', header: 'Status', style: 'min-width: 100px;' },
          {
            field: 'revoked', header: 'Revoked', style: 'min-width: 100px;', slot: {
              render: (slotProps) => (slotProps.data.revoked ? 'Yes' : 'No')
            }
          },
          {
            field: 'auto_rule_out', header: 'Aro', style: 'min-width: 100px;', slot: {
              render: (slotProps) => (slotProps.data.auto_rule_out ? 'Yes' : 'No')
            },
          },
          {
            field: 'first_prov_yes_seq_any_tc', header: 'Py_f_s', style: 'min-width: 100px;',
            slot: {
              render: (slotProps) => {
                return `
                    py: (${slotProps.data.first_prov_yes_seq_any_tc || ''}, ${slotProps.data.second_prov_yes_seq_any_tc || ''}),
                    f: ${slotProps.data.first_accepted_seq_any_tc || ''} : ${slotProps.data.first_accepted_organ_any_tc || ''},
                    s: ${slotProps.data.second_accepted_seq_any_tc || ''} : ${slotProps.data.second_accepted_organ_any_t || ''}
                  `;
              },
            },
          },
          { field: 'offer_date', header: 'Offer date', style: 'min-width: 100px;' },
          { field: 'created_at', header: 'Created at', style: 'min-width: 100px;' },
          { field: 'updated_at', header: 'Updated at', style: 'min-width: 100px;' }
        ],
      };
      this.offerTableConfig = {
        tableId: 'troubleshoot-offers-table',
        data: this.data.offers,
        loading: this.loading,
        emptyMessage: 'No offers found!',
        tableHeaders: 'bk_offers',
        paginate: true,
        columns: [
          { field: 'match.donor.code', header: 'Donor', style: 'min-width: 100px;' },
          { field: 'match.code', header: 'Match', style: 'min-width: 100px;' },
          { field: 'match.transplant_center.code', header: 'TxC', style: 'min-width: 100px;' },
          { field: 'seq', header: 'Seq', style: 'min-width: 100px;' },
          { field: 'rank', header: 'Rank', style: 'min-width: 100px;' },
          { field: 'status', header: 'Status', style: 'min-width: 100px;' },
          {
            field: 'auto_rule_out', header: 'Aro', style: 'min-width: 100px;', slot: {
              render: (slotProps) => (slotProps.data.auto_rule_out ? 'Yes' : 'No')
            },
          },
          { field: 'offer_date', header: 'Offer date', style: 'min-width: 100px;' },
          { field: 'created_at', header: 'Created at', style: 'min-width: 100px;' },
          { field: 'updated_at', header: 'Updated at', style: 'min-width: 100px;' }
        ],
      };
    }
  }
}
</script>
<template>
  <div class="row" v-if="canShowPanel">
    <div class="col-12">
      <Panel header="Responses" toggleable>  
          <DynamicTable
            :table-id="responseTableConfig.tableId"
            :table-data="responseTableConfig.data"
            :loading="responseTableConfig.loading"
            :empty-message="responseTableConfig.emptyMessage"
            :table-headers="responseTableConfig.tableHeaders"
            :columns="responseTableConfig.columns"
            :paginate="responseTableConfig.paginate"
          />
          <Divider type="dashed" />
      </Panel>
    </div>
  </div>
</template>

<script>

import Panel from 'primevue/panel';
import Divider from 'primevue/divider';
import DynamicTable from "@/src/components/shared/DynamicTable.vue";

export default {
  components: {
    Panel,
    Divider,
    DynamicTable
  },
  computed: {
    canShowPanel() {
      return  this.data !== null || this.responseTableConfig !== null ? true : false;
    },
  },
  props: {
    data: { required: true, type: Array },
    loading: { required: true, type: Boolean },
  },
  data() {
    return {
      responseTableConfig: null
    }
  },
  watch: {
    data() {
      this.populateData();
    }
  },
  methods: {
    populateData() {
      if (this.data == null) {
        this.responseTableConfig = null;
        return null;
      }
       
      this.responseTableConfig = {
        tableId: 'troubleshoot-responses-table',
        data: this.data,
        loading: this.loading,
        emptyMessage: 'No data found!',
        tableHeaders: 'Responses',
        paginate: true,
        columns: [
          { field: 'donor', header: 'Donor', style: 'min-width: 100px;' },
          { field: 'match', header: 'Match', style: 'min-width: 100px;' },
          { field: 'tc', header: 'TxC', style: 'min-width: 100px;' },
          { field: 'organ', header: 'Organ', style: 'min-width: 100px;' },
          { field: 'seq', header: 'Seq', style: 'min-width: 100px;' },
          { field: 's_alt', header: 's_alt', style: 'min-width: 100px;' },
          { field: 's_txt', header: 's_txt', style: 'min-width: 100px;' },
          { field: 'a_rank', header: 'a_rank', style: 'min-width: 100px;' },
          { field: 'a_resp', header: 'a_resp', style: 'min-width: 100px;' },
          { field: 'o_acpt', header: 'o_acpt', style: 'min-width: 100px;' },
          { field: 'o_resp', header: 'o_resp', style: 'min-width: 100px;' },
          { field: 'e_resp', header: 'e_resp', style: 'min-width: 100px;' },
          { field: 's_seq_at', header: 's_seq_at', style: 'min-width: 100px;' },
          { field: 'a_updated_at', header: 'a_updated_at', style: 'min-width: 100px;' },
          { field: 'o_updated_at', header: 'o_updated_at', style: 'min-width: 100px;' },
          { field: 'e_created_date', header: 'e_created_date', style: 'min-width: 100px;' }
        ],
      };
    }
  }
}
</script>
<template>
  <div class="row" v-if="canShowPanel">
    <div class="col-12">
      <Panel header="Data Ingest" toggleable>
          <div v-for="(config, index) in [unosemailTableConfig, donorEditTableConfig, acceptanceListTableConfig, matchListTableConfig, seqStatusTableConfig, declineMatchTableConfig, offerDetailsTableConfig]" :key="index">
            <DynamicTable
              :table-id="config.tableId"
              :table-data="config.data"
              :loading="config.loading"
              :empty-message="config.emptyMessage"
              :table-headers="config.tableHeaders"
              :columns="config.columns"
              :paginate="config.paginate"
            />
            <Divider type="dashed" />
        </div>
      </Panel>
    </div>
  </div>
</template>

<script>

import Panel from 'primevue/panel';
import Divider from 'primevue/divider';
import DynamicTable from "@/src/components/shared/DynamicTable.vue";

export default {
  components: {
    Panel,
    Divider,
    DynamicTable
  },
  props: {
    data: { required: true, type: Array },
    loading: { required: true, type: Boolean },
  },
  computed: {
    canShowPanel() {
      return  this.data !== null || this.unosemailTableConfig !== null || this.donorEditTableConfig !== null || this.acceptanceListTableConfig !== null || this.matchListTableConfig !== null || this.seqStatusTableConfig !== null || this.declineMatchTableConfig !== null || this.offerDetailsTableConfig !== null ? true : false;
    },
  },
  data() {
    return {
      unosemailTableConfig: null,
      donorEditTableConfig: null,
      acceptanceListTableConfig: null,
      matchListTableConfig: null,
      seqStatusTableConfig: null,
      declineMatchTableConfig: null,
      offerDetailsTableConfig: null
    }
  },
  watch: {
    data() {
      this.populateData();
    }
  },
  methods: {
    populateData() {
      if (this.data == null) {
        this.unosemailTableConfig = null;
        this.donorEditTableConfig = null;
        this.acceptanceListTableConfig = null;
        this.matchListTableConfig = null;
        this.seqStatusTableConfig = null;
        this.declineMatchTableConfig = null;
        this.offerDetailsTableConfig = null;

        return;
      }
       
      this.unosemailTableConfig = {
        tableId: 'troubleshoot-unosemail-table',
        data: this.data.unos_emails,
        loading: this.loading,
        emptyMessage: 'No emails found!',
        tableHeaders: 'Unosemail',
        paginate: true,
        columns: [
          { field: 'donor', header: 'Donor', style: 'min-width: 100px;' },
          { field: 'match', header: 'Match', style: 'min-width: 100px;' },
          { field: 'tc', header: 'TxC', style: 'min-width: 100px;' },
          { field: 'organ', header: 'Organ', style: 'min-width: 100px;' },
          { field: 'seq', header: 'Seq', style: 'min-width: 100px;' },
          {
            field: 'isprimary', header: 'Is Primary', style: 'min-width: 100px;', slot: {
              render: (slotProps) => (slotProps.data.isprimary ? 'Yes' : 'No')
            }
          },
          {
            field: 'isbackup', header: 'Is Backup', style: 'min-width: 100px;', slot: {
              render: (slotProps) => (slotProps.data.isbackup ? 'Yes' : 'No')
            }
          },
          { field: 'numb', header: 'Numb', style: 'min-width: 100px;' },
          { field: 'subject', header: 'Subject', style: 'min-width: 100px;' },
          { field: 'recipient', header: 'Recipient', style: 'min-width: 100px;' },
          { field: 'created_date', header: 'Created date', style: 'min-width: 100px;' },
        ],
      };
      this.donorEditTableConfig = {
        tableId: 'troubleshoot-donoredit-table',
        data: this.data.donor_edit,
        loading: this.loading,
        emptyMessage: 'No donoredit details found!',
        tableHeaders: 'Donoredit',
        columns: [
          { field: 'donor', header: 'Donor', style: 'min-width: 100px;' },
          { field: 'admit_date', header: 'Admit Date', style: 'min-width: 100px;' },
          { field: 'created_at', header: 'Created At', style: 'min-width: 100px;' },
          { field: 'updated_at', header: 'Updated At', style: 'min-width: 100px;' },
          { field: 'updated_by', header: 'Updated By', style: 'min-width: 100px;' }
        ]
      };
      this.acceptanceListTableConfig = {
        tableId: 'troubleshoot-acceptancelist-table',
        data: this.data.acceptance_list,
        loading: this.loading,
        emptyMessage: 'No acceptanceList data found!',
        tableHeaders: 'Acceptancelist',
        paginate: true,
        columns: [
          { field: 'donor', header: 'Donor', style: 'min-width: 100px;' },
          { field: 'match', header: 'Match', style: 'min-width: 100px;' },
          { field: 'recipients', header: 'Recipients', style: 'min-width: 100px;' },
          { field: 'first_three_provincial_accept_seqs', header: 'Prov 3', style: 'min-width: 100px;' },
          { field: 'accepted_seqs', header: 'Accepted', style: 'min-width: 100px;' },
          { field: 'curdate', header: 'Curdate', style: 'min-width: 100px;' },
          { field: 'created_at', header: 'Created At', style: 'min-width: 100px;' },
          { field: 'updated_at', header: 'Updated At', style: 'min-width: 100px;' },
          { field: 'updated_by', header: 'Updated By', style: 'min-width: 100px;' }
        ],
      };
      this.matchListTableConfig = {
        tableId: 'troubleshoot-matchlist-table',
        data: this.data.match_lists,
        loading: this.loading,
        emptyMessage: 'No matchlist data found!',
        tableHeaders: 'Matchlist',
        paginate: true,
        columns: [
          { field: 'donor', header: 'Donor', style: 'min-width: 100px;' },
          { field: 'match', header: 'Match', style: 'min-width: 100px;' },
          { field: 'tc', header: 'TxC', style: 'min-width: 100px;' },
          { field: 'organ_group', header: 'Organ group', style: 'min-width: 100px;' },
          { field: 'first_seq', header: 'First seq', style: 'min-width: 100px;' },
          { field: 'recipients', header: 'Recipients', style: 'min-width: 100px;' },
          { field: 'offer_date', header: 'Offer date', style: 'min-width: 100px;' },
          { field: 'created_at', header: 'Created At', style: 'min-width: 100px;' },
          { field: 'updated_at', header: 'Updated At', style: 'min-width: 100px;' },
          { field: 'updated_by', header: 'Updated By', style: 'min-width: 100px;' }
        ],
      };
      this.seqStatusTableConfig = {
        tableId: 'troubleshoot-seqstatus-table',
        data: this.data.seq_statuses,
        loading: this.loading,
        emptyMessage: 'No seqstatus data found!',
        tableHeaders: 'Seqstatus',
        paginate: true,
        columns: [
          { field: 'donor', header: 'Donor', style: 'min-width: 100px;' },
          { field: 'match', header: 'Match', style: 'min-width: 100px;' },
          { field: 'tc', header: 'TxC', style: 'min-width: 100px;' },
          { field: 'revoked', header: 'Revoked', style: 'min-width: 100px;' },
          { field: 'recipients', header: 'Recipients', style: 'min-width: 100px;' },
          { field: 'first_three_seqs', header: 'First 3', style: 'min-width: 100px;' },
          { field: 'created_at', header: 'Created At', style: 'min-width: 100px;' },
          { field: 'updated_at', header: 'Updated At', style: 'min-width: 100px;' },
          { field: 'updated_by', header: 'Updated By', style: 'min-width: 100px;' }
        ],
      };
      this.declineMatchTableConfig = {
        tableId: 'troubleshoot-declinematch-table',
        data: this.data.decline_matches,
        loading: this.loading,
        emptyMessage: 'No declinematch data found!',
        tableHeaders: 'Declinematch',
        paginate: true,
        columns: [
          { field: 'donor', header: 'Donor', style: 'min-width: 100px;' },
          { field: 'match', header: 'Match', style: 'min-width: 100px;' },
          { field: 'tc', header: 'TxC', style: 'min-width: 100px;' },
          { field: 'aro', header: 'Aro', style: 'min-width: 100px;' },
          { field: 'physician', header: 'Physician', style: 'min-width: 100px;' },
          { field: 'created_at', header: 'Created At', style: 'min-width: 100px;' },
          { field: 'updated_at', header: 'Updated At', style: 'min-width: 100px;' },
          { field: 'updated_by', header: 'Updated By', style: 'min-width: 100px;' }
        ],
      };
      this.offerDetailsTableConfig = {
        tableId: 'troubleshoot-offerdetails-table',
        data: this.data.offer_details,
        loading: this.loading,
        emptyMessage: 'No offerdetails data found!',
        tableHeaders: 'Offerdetails',
        paginate: true,
        columns: [
          { field: 'donor', header: 'Donor', style: 'min-width: 100px;' },
          { field: 'match', header: 'Match', style: 'min-width: 100px;' },
          { field: 'tc', header: 'TxC', style: 'min-width: 100px;' },
          { field: 'seq', header: 'Seq', style: 'min-width: 100px;' },
          {
            field: 'revoked', header: 'Revoked', style: 'min-width: 100px;', slot: {
              render: (slotProps) => (slotProps.data.revoked ? 'Yes' : 'No')
            }
          },
          { field: 'contact_name', header: 'Contact name', style: 'min-width: 100px;' },
          { field: 'change_user', header: 'Change User', style: 'min-width: 100px;' },
          { field: 'change_datetime', header: 'Change datetime', style: 'min-width: 100px;' },
          { field: 'hide_offer_accept', header: 'Hideofferaccept', style: 'min-width: 100px;' },
          { field: 'offer_response', header: 'Offer Response', style: 'min-width: 100px;' },
          { field: 'offer_date', header: 'Offer Date', style: 'min-width: 100px;' },
          { field: 'notification_date', header: 'Notification Date', style: 'min-width: 100px;' },
          { field: 'created_at', header: 'Created At', style: 'min-width: 100px;' },
          { field: 'updated_at', header: 'Updated At', style: 'min-width: 100px;' },
          { field: 'updated_by', header: 'Updated By', style: 'min-width: 100px;' }
        ],
      };
    }
  }
}
</script>
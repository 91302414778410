
<template>
  <PortalTable
    :table-id="tableId"
    :table-data="tableData"
    :loading="loading"
    :empty="emptyMessage"
    :paginator="paginate"
    mode="local"
  >
    <template #headers>
      <div class="table-header">
        <span><b>{{ tableHeaders }}</b></span>
      </div>
    </template>
    <template #columns>
      <Column
        v-for="column in columns"
        :key="column.field"
        :field="column.field"
        :header="column.header"
        :style="column.style"
      >
        <template v-if="column.slot?.render" #body="slotProps">
          {{ column.slot.render(slotProps) }}
        </template>
      </Column>
    </template>
  </PortalTable>
</template>

<script>
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';

export default {
  components: {
    PortalTable,
    Column
  },
  props: {
    tableId: { type: String, required: true },
    tableData: { type: Array, required: true },
    loading: { type: Boolean, default: false },
    emptyMessage: { type: String, default: "No data found!" },
    tableHeaders: { type: String, default: "Table Header" },
    columns: { type: Array, required: true, default: () => [] },
    paginate: { type: Boolean, default: false }
  }
};
</script>

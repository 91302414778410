import { defineStore } from 'pinia'
import beApiClient from "@/src/be-api-client";
import {APIRoute, EP, APIBaseRoute} from "@/src/endpoints";
import { handleErrors, makeRequest } from "@/src/utils";

export const useMatchStore = defineStore('match', {
  state: ()=> {
    return {
      // lookup data
      
    }
  },
  actions: {
    // Generic load data method
    loadEntity(url, key, params = {}) {
      return new Promise((resolve, reject) => {
        beApiClient.get(url, params).then(response => {
          this[key] = response.data;
          resolve();
        }).catch(error => {
          const message = handleErrors(error);
          reject(message);
        });
      });
    },
    loadMatch(id, external=false) {
      return makeRequest(beApiClient.get, APIRoute(external? EP.external_clinical.matches.show : EP.matches.show, { id: id }));
    },
    updateMatch(id, payload){
      return makeRequest(beApiClient.put, APIRoute(EP.matches.decline, { id: id }), { match: payload });
    },
    updateOffer(id, payload){
      return makeRequest(beApiClient.put, APIRoute(EP.offers.update, { id: id }), { match: payload });
    },
    troubleshootMatches(payload) {
      return makeRequest(beApiClient.get, APIRoute(EP.admin.troubleshoot.matches.search), { params: payload });
    }
  }
});
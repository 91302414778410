<template>
  <CardSection header-class="title-bar" :error="majorError" :loading="isSavableStateLoading">
    <template #header>
      Troubleshoot Matches
    </template>
    <template #body>
      <SubSection>
        <template #body>
        <VeeForm @submit="onSubmit">
          <div class="row">
            <TextInput v-model="matchCode"
              name="matchCode"
              label="Match Code"
              rules="required"
              col-style="form-group-standard-column" />
              <MultiSelectInput v-model="tc"
                name="team-transplant-center"
                label="Transplant Center"
                :options="teamStore.allTransplantCenters"
                label-key="code"
                value-key="code"
                col-style="form-group-standard-column" />
            <TextInput v-model="donorCode"
              name="donorCode"
              label="Donor Code"
              col-style="form-group-standard-column" />
            <TextInput v-model="seq"
              name="seq"
              label="Seq"
              type="number"
              rules="min_value:1"
              col-style="form-group-standard-column" />
          </div>
          
            <ActionToolbar :component-state="currentState" 
              :error-message="formError" 
              :success-message="successMessage" 
              primary-button-text="Search"
              @secondary-click="cancel" /> 
         
        </VeeForm>
      </template>
    </SubSection>
    <hr>

    <DashboardTables :data="dashboardMatchDetails" />
    <PluginTables :data="pluginMatchDetails" />
    <PluginResponses :data="pluginResponses" />
    </template>
  </CardSection>
</template>
<script>
import CardSection from "@/src/components/shared/CardSection.vue";
import SubSection from "@/src/components/shared/SubSection.vue";
import { savableStateMixin } from "@/src/mixins/savableStateMixin";
import { useMatchStore } from '@/src/stores/match';
import TextInput from "@/src/components/shared/FormComponents/TextInput.vue";
import ActionToolbar from "@/src/components/shared/ActionToolbar.vue";
import { Form as VeeForm } from 'vee-validate';
import { useTeamStore } from '@/src/stores/team';
import MultiSelectInput from "@/src/components/shared/FormComponents/MultiSelectInput.vue";
import DashboardTables from '@/src/components/admin-area/troubleshoot/matches/_dashboardTables.vue'
import PluginTables from '@/src/components/admin-area/troubleshoot/matches/_pluginTables.vue'
import PluginResponses from '@/src/components/admin-area/troubleshoot/matches/_pluginResponses.vue'

export default {
  components: {
    CardSection,
    TextInput,
    VeeForm,
    ActionToolbar,
    MultiSelectInput,
    DashboardTables,
    PluginTables,
    SubSection,
    PluginResponses
  },
  mixins: [ savableStateMixin ],
  setup() {
    const matchStore = useMatchStore();
    const teamStore = useTeamStore();
    return {
      matchStore, teamStore
    }
  },
  data() {
    return {
      donorCode: null,
      matchCode: null,
      tc: null,
      seq: null,
      dashboardMatchDetails: null,
      pluginMatchDetails: null,
      pluginResponses: null
    }
  },
  async mounted() {
    try {
      this.setSavableStateLoading();
      await this.teamStore.loadAllTransplantCentres();
      this.setSavableStateIdle(); 
    } catch(error) {
      this.handleMajorError(error);
    }
  },
  methods: {
    async onSubmit() {
      this.setSavableStateProcessing();
      try {
        const payload = {
          match: this.matchCode,
          donor: this.donorCode,
          tc: this.tc,
          seq: this.seq
        }
        const response = await this.matchStore.troubleshootMatches(payload);
        this.dashboardMatchDetails = response.dashboard;
        this.pluginMatchDetails = response.plugin;
        this.pluginResponses = response.responses;
        this.setSavableStateIdle();
      } catch(error) {
        this.handleFormError(error);
      }
    },
    cancel() {
      this.donorCode = null;
      this.matchCode = null;
      this.tc = null;
      this.seq = null;
      this.dashboardMatchDetails = null;
      this.pluginMatchDetails = null;
      this.pluginResponses = null;
    }
  }
}
</script>

<template class="page-content">
  <ModalLayout :ref="modalRef" :modal-id="modalRef" :loading="currentState === SaveStates.Loading" @close="modalClosed">
    <template #modal-header>
      <h5 class="modal-title">
        Notes
      </h5>
    </template>
    <template #modal-body>
      <div v-if="selectedMatch && selectedMatch.coordinator_note && selectedMatch.coordinator_note.ordered_versions && selectedMatch.coordinator_note.ordered_versions.length > 0" class="row">
        <div class="col-sm-12 mb-2">
          <PortalTable :table-id="`txc-notes-table-${match_id}`"
            :table-data="selectedMatch.coordinator_note.ordered_versions"
            :total-records="selectedMatch.coordinator_note.ordered_versions.count"
            :loading="loading">
            <template #columns>
              <Column field="created_at" header="Date Time" style="min-width: 100px;">
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.created_at) }}
                </template>
              </Column>
              <Column field="changed_by_user_role" header="Role" style="min-width: 100px;" />
              <Column field="changed_by" header="User" style="min-width: 100px;" />
              <Column field="changed_to.note" header="Notes" style="white-space: pre-wrap; min-width: 100px;" />
            </template>
          </PortalTable>
        </div>
      </div>
      <div v-else class="row">
        <div class="col-sm-12 mb-2"> 
          <p>No notes found!!</p>
        </div>
      </div>
    </template>
  </ModalLayout>
</template>


<script lang="js">
import beApiClient from "@/src/be-api-client";
import { APIRoute, EP } from "~/src/endpoints";
import ModalLayout from "@/src/components/shared/ModalLayout.vue";
import {savableStateMixin} from "@/src/mixins/savableStateMixin";
import PortalTable from "@/src/components/shared/PortalTable.vue";
import Column from 'primevue/column';
import { modalActionsMixin } from "../../mixins/modalActionsMixin";

export default {
  components: {
    ModalLayout,
    PortalTable,
    Column
  },
  mixins: [ modalActionsMixin, savableStateMixin ],
  props: {
    matchId: {
      required: true,
      default: null,
      type: String
    }
  }, 
  emits: ['close'],
  data() {
    return {
      selectedMatch: null,
      loading: false,
      modalRef: 'txCNotesModal'
    }
  },
  watch: {
    matchId(newRow, _oldRow) {
      this.getModalData();
      this.disableSaving = false;
    }
  },
  mounted() {
    this.getModalData();
  },
 
  methods: {
    getModalData() {
      if(!this.matchId) return;

      this.currentState = this.SaveStates.Loading;

      // Get data
      this.loading = true;
      const ep = APIRoute(EP.external_clinical.matches.show, { id: this.matchId });
      beApiClient.get(ep).then((response) => {
        this.selectedMatch = response.data;
        this.currentNote = this.selectedMatch.coordinator_note?.note ? this.selectedMatch.coordinator_note.note : '';
      }).catch((e) => {
        this.closeModal()
      }).finally(() => {
        this.currentState = this.SaveStates.Blank;
        this.loading = false;
      })
    },
    modalClosed() {
      this.currentState = this.SaveStates.Blank;
      this.$emit('close')
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    },
  }
  
}
</script>